.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  font-family: Cambria, serif;
  /* Fallback to serif if Cambria is not available */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #F97316 !important;
  background-color: #F97316 !important;
  /* Orange color */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}